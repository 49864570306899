<script setup>
import { onMounted } from 'vue';
import { getDataObjectById } from 'o365-dataobject';
import { getOrCreateProcedure } from 'o365-modules';
import ActionsCard from 'sja.vue.libraries.components.ActionsCard.vue';

const props = defineProps({
    hazardRow: Object,
    sjaID: Number,
    itemID: Number,
    readOnly: Boolean,
    participants: Boolean
});

let dsHazards = getDataObjectById(`dsTasksHazards_${props.sjaID}`)
const procCreateActionRow = getOrCreateProcedure({ id: "procCreateActionRow", procedureName: "astp_SJA_CreateActionRow" });

async function newRow(row) {
    let dsTasksHazardsActions = null;
    let dsTasksHazardsActionsResp = null;
    try {
        await procCreateActionRow.execute({ ID: row.ID });
        dsTasksHazardsActions = getDataObjectById(`dsTasksHazardsActions_${row.SJA_ID}`);
        dsTasksHazardsActionsResp = getDataObjectById(`dsTasksHazardsActionsResp_${row.SJA_ID}`);
        await dsTasksHazardsActions.load();
        await dsTasksHazardsActionsResp.load();
    } catch (error) {
        console.error("Error loading data:", error);
    }
}

onMounted(() => {
    if (!props.readOnly) {
        checkActions();
    }
})

function checkActions() {
    let allActionTabs = document.querySelectorAll(`.action-tab-${props.hazardRow.ID}`);
    if (allActionTabs.length > 0) {
        allActionTabs.forEach((tab) => {
            tab.innerHTML = $t('New Actions') + `<i type="button" class="new-action-button-${props.hazardRow.ID} ms-2 bi bi-plus-circle"></i>`;
        })
        const icon = document.querySelector(`.new-action-button-${props.hazardRow.ID}`);
        icon.addEventListener("click", function () {
            newRow(props.hazardRow);
        });
        icon.addEventListener("mouseover", function () {
            const title = $t('Add a new action.');
            icon.setAttribute('title', title);
        });
    }
}

</script> 

<template>
    <OTabs>
        <OTab :title="$t('New Actions')" :linkClass='"action-tab-" + props.hazardRow.ID' class="mb-2"
            :active="props.hazardRow.ExistingActions == null">
            <ActionsCard class="action-card" :hazardRow="props.hazardRow" :sjaID="props.sjaID" :itemID="props.itemID"
                :readOnly="props.readOnly" :participants="props.participants" />
        </OTab>
        <OTab :title="$t('Existing Actions')" :active="props.hazardRow.ExistingActions != null">
            <OContentEditable class="w-100 h-100 mb-2 p-1 text-break action-card" style="min-height:109.6px"
                :class="{ 'inner-table-editable': !props.readOnly, 'inner-table-not-editable': props.readOnly }"
                :isEditable="!props.readOnly" v-model="props.hazardRow.ExistingActions" @blur="dsHazards.save()" />
        </OTab>
    </OTabs>
</template>

<style scoped>
.inner-table-editable {
    border: 1.5px solid var(--o365-grid-border) !important;
    border-radius: var(--bs-border-radius) !important;
}

.inner-table-not-editable {
    border: 1.5px solid var(--o365-grid-border) !important;
    border-radius: var(--bs-border-radius) !important;
}

.inner-table-editable:focus,
.inner-table-editable:hover {
    outline: 0;
    border: 1.5px solid rgba(70, 130, 180, .25) !important;
    box-shadow: 0px 0px 0px 2px rgba(70, 130, 180, .25) !important;
}

.action-card {
    border-style: none solid solid solid !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
</style>